import React from 'react'
import ChatBot, { Button } from 'react-chatbotify'

// interface RDChatBotifyState {
//   isOpen: boolean
//   disableUserInput: boolean
//   options: string[]
//   paths: any[]
//   messages: Message[]
//   sessionID: string
// }

export interface RDChatBotifyProps {
  sessionID: string
}

const RDChatBotify = (props: RDChatBotifyProps) => {
  console.log(props.sessionID)
  // const [rdChatBotifyState, setRdChatBotifyState] = useState<RDChatBotifyState>()

  const flow = {
    start: {
      message: `Hey there! I am Raindrop's AI Assistant. How may I help you?`,
      path: 'loop',
    },
    loop: {
      message: (params) => {
        run(params)
        // console.log('message', params)
        // const res = await fetchData(params.userInput)
        // await processResponse(res)
        // params.injectMessage(
        //   // Inject Only Custom Components
        //   <ChatBotifyRootComponent
        //     raindropAssistantResponse={res.data?.message as any}
        //     setCustomComponentMessages={setCustomComponentMessages}
        //     customComponentMessages={customComponentMessages}
        //     chatBotifyParams={params}
        //     setPaths={setPaths}
        //     setSelectedOption={setSelectedOption}
        //     fetchData={fetchData}
        //     // setOptions={setOptions}
        //   />
        // )
      },
      // transition: { duration: 0 },
      path: 'loop',
    },
  }

  const run = (params) => {
    sendRequest({
      userInput: params?.userInput,
      params,
    })
  }

  const sendRequest = ({ userInput, params }) => {
    console.log(userInput, params)
  }

  // const sendRequest = async ({ userInput, params }) => {
  // try {
  //   const response = await RaindropAssistantService.detectIntent(
  //     appContext.auth.authToken,
  //     userInput,
  //     props.sessionID
  //   )
  //   if (response) {
  //     params.injectMessage(
  //       <ChatBotifyRootComponent
  //         raindropAssistantResponse={response.data?.message as any}
  //         setCustomComponentMessages={setCustomComponentMessages}
  //         customComponentMessages={customComponentMessages}
  //         chatBotifyParams={params}
  //         setPaths={setPaths}
  //         setSelectedOption={setSelectedOption}
  //         fetchData={sendRequest}
  //         // setOptions={setOptions}
  //       />
  //     )
  //   } else {
  //     console.error('Unexpected response structure:', response)
  //     return 'Unexpected response from server.'
  //   }
  // } catch (err) {
  //   console.error('Error during Axios request:', err)
  //   return 'An error occurred while processing your request.'
  // }
  //   return 'Something Happened'
  // }

  return (
    <ChatBot
      flow={flow}
      styles={{
        chatButtonStyle: {
          height: '40px',
          width: '40px',
          bottom: '80px',
          right: '30px',
        },
        tooltipStyle: {
          bottom: '80px',
          right: '85px',
          fontSize: '14px',
        },
        chatWindowStyle: {
          width: '100%',
          height: '100%',
        },
      }}
      settings={{
        // advance: {
        //   useAdvancedMessages: true,
        //   useAdvancedPaths: true,
        // },
        general: {
          primaryColor: '#34a1d5',
          secondaryColor: '#004991',
          fontFamily: `"Gotham HTF", "Roboto", "Helvetica Neue", sans-serif`,
          showHeader: true,
          showFooter: true,
          showInputRow: true,
          // actionDisabledIcon: actionDisabledIcon,
          embedded: true,
          desktopEnabled: true,
          mobileEnabled: true,
          flowStartTrigger: 'ON_LOAD',
        },
        header: {
          title: 'Raindrop Assistant',
          // avatar: RaindropIcon,
          buttons: [Button.CLOSE_CHAT_BUTTON],
          // closeChatIcon: RaindropIcon,
        },
        footer: {
          text: 'RaindropAI',
        },
        chatHistory: { storageKey: 'example_smart_conversation' },
        // chatButton: {
        //   icon: RaindropIcon,
        // },
        tooltip: {
          mode: 'CLOSE',
          text: 'Raindrop AI',
        },
      }}
    />
  )
}

export default RDChatBotify
