import React from 'react'
import Grid, { Grid2Props } from '@mui/material/Grid2'

interface RDGridProps extends Grid2Props {
  nopaddingtop?: string
}

export const RDGrid = React.forwardRef((props: RDGridProps, ref: any) => {
  return (
    <Grid
      style={trim(props.nopaddingtop).toLowerCase() === 'true' ? { paddingTop: 0 } : { paddingTop: '6px' }}
      {...props}
      ref={ref}
    />
  )
})

const trim = (s: string) => {
  if (!s || typeof s !== 'string') {
    return ''
  }
  return s.trim()
}
