import React, { useEffect, useState } from 'react'
import { signInO365 } from '../../utilities/office-apis-helpers'
import Progress from './Progress'
import { ThemeProvider } from '@mui/material'
import { ThemeService } from '../services/ThemeService'
import { RDGrid } from './Shared/Wrappers/RDGrid'
import AddInBody from './AddInBody'
// import { RDGrid } from './Shared/Wrappers/RDGrid'
import OfficeAddinMessageBar from './OfficeAddinMessageBar'
import { CircularProgress } from '@mui/material'
import { RDButton } from './RDButton'
import { TopMenuBar } from './TopMenuBar'

export interface AppState {
  authStatus?: string
  fileFetch?: string
  headerMessage?: string
  errorMessage?: string
  authToken?: string
  userName?: string
  filter?: string
}
export interface AppProps {
  title: string
  isOfficeInitialized: boolean
}

export const App = (props: AppProps) => {
  const { title, isOfficeInitialized } = props
  const [state, setState] = useState<AppState>(getInitialState())

  const login = async () => {
    await signInO365(setState)
  }

  useEffect(() => {
    if (state.authStatus === 'notLoggedIn') {
      login()
    }
  }, [state?.authStatus])

  if (!isOfficeInitialized) {
    return <Progress title={title} logo='assets/logo.png' message='Please sideload your add-in to see app body.' />
  }
  return (
    <>
      <ThemeProvider theme={ThemeService.getDefaultTheme()}>
        {state?.errorMessage ? <OfficeAddinMessageBar onDismiss={() => {}} message={state?.errorMessage} /> : null}
        <RDGrid
          container
          direction='column'
          sx={{
            paddingTop: '0px !important',
            bgcolor: '#FFFFFF',
          }}>
          <TopMenuBar appSetState={setState} appState={state} />
          {state?.authStatus === 'notLoggedIn' && (
            <RDGrid item>
              <RDButton onClick={login}>{'Sign in to Office 365 '}</RDButton>
            </RDGrid>
          )}
          {state?.authStatus === 'loginInProcess' && (
            <RDGrid container item sx={{ marginTop: '24px' }}>
              <RDGrid container item justifyContent={'center'}>
                <RDGrid item>
                  <CircularProgress />
                </RDGrid>
              </RDGrid>
              <RDGrid container item justifyContent={'center'}>
                <RDGrid item>
                  <RDGrid item>{`Sign in to continue`}</RDGrid>
                </RDGrid>
              </RDGrid>
            </RDGrid>
          )}
          {state?.authStatus === 'loggedIn' && <AddInBody state={state} setState={setState}></AddInBody>}
        </RDGrid>
      </ThemeProvider>
    </>
  )
}
const getInitialState = () => {
  return {
    authStatus: 'notLoggedIn',
    fileFetch: 'notFetched',
    headerMessage: 'Welcome',
    errorMessage: null,
    authToken: null,
    userName: null,
    filter: undefined,
  }
}
