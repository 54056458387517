import React from 'react'
import { AppState } from './App'
// import { Box } from '@mui/material'
import SplitPane from 'react-split-pane-v2'
import RDChatBotify from './Shared/Wrappers/RDChatBotify'
import { TopPaneComponent } from './TopPaneComponent'
// import { RDTabs } from './TabComponents/RDTab'
// import { RDSwipeableViews } from './Shared/Wrappers/RDSwippableViews'
// import { RDTab } from './Shared/Wrappers/RDTab'
// import { grey } from '@mui/material/colors'
// import { RDGrid } from './Shared/Wrappers/RDGrid'

interface AddInBodyProps {
  state: AppState
  setState: React.Dispatch<React.SetStateAction<AppState>>
}

// const systemA11yProps = (name: string, index: any) => {
//   return {
//     id: `scrollable-force-tabpanel-${name}-${index}`,
//     'aria-controls': `scrollable-force-tabpanel-${name}-${index}`,
//   }
// }

const AddInBody = (props: AddInBodyProps) => {
  console.log(props)
  return (
    <SplitPane split={'horizontal'} allowResize>
      <TopPaneComponent token={props.state.authToken} filter={props.state.filter} />
      <RDChatBotify sessionID='aasdfgd78'></RDChatBotify>
    </SplitPane>
    // <RDSwipeableViews
    //   index={0}
    //   // onChangeIndex={() => console.log('HandleChange CLicked')}
    //   tabs={
    //     <RDTabs
    //       value={0}
    //       sx={{
    //         width: '100%',
    //         borderBottom: `1px solid #D6D6D6`,
    //         height: 50,
    //       }}
    //       orientation={'horizontal'}
    //       indicatorColor='primary'
    //       textColor='primary'
    //       variant='scrollable'
    //       scrollButtons='auto'
    //       aria-label='scrollable auto tabs example'
    //       // style={{ borderBottom: `1px solid #D6D6D6` }}
    //       // onChange={(horizontalTabValue?: number) => props.setState((prev) => ({ ...prev, horizontalTabValue }))}
    //     >
    //       <RDTab
    //         label={`Assist`}
    //         {...systemA11yProps('suppliers-tab', 0)}
    //         style={{ height: 'calc(100% - 51px)', minWidth: 100 }}
    //       />
    //       <RDTab
    //         label={`Analyze`}
    //         {...systemA11yProps('suppliers-tab', 1)}
    //         style={{ height: 'calc(100% - 51px)', minWidth: 100 }}
    //       />
    //     </RDTabs>
    //   }></RDSwipeableViews>
  )
}

export default AddInBody
