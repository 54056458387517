import { AxiosResponse } from 'axios'
import { ClauseListInterface } from '../src/components/ModalComponent'
import { AppState } from '../src/components/App'

/*
     Interacting with the Office document
*/

export const writeFileNamesToWorksheet = async (
  result: AxiosResponse,
  displayError: (x: string) => void,
  token: string
) => {
  try {
    await Word.run(async (context) => {
      let body = context.document.body
      // const data = [[result.data.value[0].name], [result.data.value[1].name], [result.data.value[2].name]]
      body.insertParagraph('Token: ' + token + ' ' + result.data, Word.InsertLocation.end)
      await context.sync()
    })
    /*await Excel.run((context: Excel.RequestContext) => {
            const sheet = context.workbook.worksheets.getActiveWorksheet();

            const data = [
                [result.data.value[0].name],
                [result.data.value[1].name],
                [result.data.value[2].name]];

            const range = sheet.getRange('B5:B7');
            range.values = data;
            range.format.autofitColumns();

            return context.sync();
        });*/
  } catch (error) {
    if (displayError) {
      displayError(error.toString())
    }
  }
}

export const writeTexttoWordFile = async (
  result: string,
  displayError: (x: string) => void,
  fontsize: number,
  bold: boolean
) => {
  try {
    await Word.run(async (context) => {
      // Create a proxy sectionsCollection object.
      //const mySections = context.document.sections

      // Queue a command to load the sections.
      //mySections.load('body/style')

      // Synchronize the document state by executing the queued commands,
      // and return a promise to indicate task completion.
      //await context.sync()

      // Create a proxy object the primary header of the first section.
      // Note that the header is a body object.
      //const myHeader = mySections.items[section].getHeader('Primary')

      // Queue a command to insert text at the end of the header.
      //myHeader.insertText(result.replace(/\*/g, ''), Word.InsertLocation.end)

      // Queue a command to wrap the header in a content control.
      // myHeader.insertContentControl()

      // Synchronize the document state by executing the queued commands,
      // and return a promise to indicate task completion.
      // await context.sync()
      // console.log('Added a header to the first section.')

      let body = context.document.body
      body.font.set({
        name: 'Helvetica',
      })
      if (bold) {
        const boldSentence: Word.Paragraph = body.insertParagraph(result.replace(/\*/g, ''), Word.InsertLocation.end)
        boldSentence.font.set({
          bold: bold,
          size: fontsize,
        })
        if (fontsize === 16) {
          boldSentence.styleBuiltIn = 'Heading1'
        } else if (fontsize === 14) {
          boldSentence.styleBuiltIn = 'Heading2'
        } else if (fontsize === 12) {
          boldSentence.styleBuiltIn = 'Heading3'
        }
      } else {
        const sentence: Word.Paragraph = body.insertParagraph(result.replace(/\*/g, ''), Word.InsertLocation.end)
        sentence.font.set({
          bold: bold,
          size: fontsize,
        })
      }

      await context.sync()
    })
  } catch (error) {
    if (displayError) {
      displayError(error.toString())
    }
  }
}

export const searchAndReplace = async (searchStr: string, replaceStr: string, displayError: (x: string) => void) => {
  try {
    await Word.run(async (context) => {
      // Create a proxy object for the document body.
      const searchResults = context.document.body.search(searchStr, { matchWholeWord: true })
      context.load(searchResults)
      await context.sync()
      for (var i = 0; i < searchResults.items.length; i++) {
        searchResults.items[i].insertText(replaceStr, 'Replace')
      }
      await context.sync()
    })
  } catch (error) {
    if (displayError) {
      displayError(error.toString())
    }
  }
}

export const readTextFromWordFile = async () => {
  await Word.run(async (context) => {
    const paragraph: Word.Paragraph = context.document.getSelection().paragraphs.getFirst()

    const text = paragraph.getText()

    await context.sync()

    writeTexttoWordFile('Text:- ' + text.value, null, 18, true)
    console.log('Text:- ' + text.value)
  })
}

export const fetchAllHeadersFromWordFile = async () => {
  await Word.run(function (context) {
    var paragraphs = context.document.body.paragraphs.load('items')
    context.load(paragraphs)
    return context.sync().then(function () {
      if (paragraphs != null) {
        // Collection of headers we'll populate later
        var headers = []

        // Iterate through the sections
        for (var i = 0; i < paragraphs.items.length; i++) {
          // Grab the header from the current section
          var item = paragraphs.items[i]
          context.load(item, ['text', 'style'])
          // we can use outlineLevel 1,2,3

          if (
            item.style === 'Heading 1' ||
            item.style === 'Heading 2' ||
            item.style === 'Heading 3' ||
            item.isListItem
          ) {
            headers.push(paragraphs.items[i])
          }
        }

        // Sync/Exectute the queued actions
        context.sync().then(function () {
          // Iterate through the headers collection
          for (var i = 0; i < headers.length; i++) {
            // Output each header's text to the console
            console.log(headers[i].text)
            writeTexttoWordFile('Header :- ' + i + ' :=' + headers[i].text, null, 18, true)
          }
        })
      }
    })
  })
}

// export const writeTexttoWordFile = async (result: any[], displayError: (x: string) => void) => {
//   try {
//     await Word.run(async (context) => {

//       let body = context.document.body
//       result.forEach((para)=>{
//         if(para.)
//       })
//       body.insertParagraph(result, Word.InsertLocation.end)
//       await context.sync()
//     })
//   } catch (error) {
//     if (displayError) {
//       displayError(error.toString())
//     }
//   }
// }

/*
    Managing the dialogs.
*/

let loginDialog: Office.Dialog
const dialogLoginUrl: string =
  location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/login/login.html'

export const signInO365 = (setState: React.Dispatch<React.SetStateAction<AppState>>) => {
  setState({ authStatus: 'loginInProcess' })

  Office.context.ui.displayDialogAsync(dialogLoginUrl, { height: 40, width: 30 }, (result) => {
    if (result.status === Office.AsyncResultStatus.Failed) {
      setState((prev) => ({ ...prev, errorMessage: `${result.error.code} ${result.error.message}` }))
    } else {
      loginDialog = result.value
      loginDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLoginMessage)
      loginDialog.addEventHandler(Office.EventType.DialogEventReceived, processLoginDialogEvent)
    }
  })

  const processLoginMessage = (arg: { message: string; origin: string }) => {
    // Confirm origin is correct.
    if (arg.origin !== window.location.origin) {
      throw new Error('Incorrect origin passed to processLoginMessage.')
    }

    let messageFromDialog = JSON.parse(arg.message)

    if (messageFromDialog.status === 'success') {
      // We now have a valid access token.
      loginDialog.close()
      const tokenWithoutQuotes = messageFromDialog.token.replace(/"/g, '')
      setState((prev) => ({
        ...prev,
        authToken: tokenWithoutQuotes,
        userName: messageFromDialog.userName,
        authStatus: 'loggedIn',
        headerMessage: 'Get Data',
      }))
    } else {
      // Something went wrong with authentication or the authorization of the web application.
      loginDialog.close()
      setState((prev) => ({ ...prev, errorMessage: messageFromDialog.result }))
    }
  }

  const processLoginDialogEvent = (arg) => {
    processDialogEvent(arg, setState)
  }
}

let logoutDialog: Office.Dialog
const dialogLogoutUrl: string =
  location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/logout/logout.html'

// From https://stackoverflow.com/questions/37764665/how-to-implement-sleep-function-in-typescript
function delay(milliSeconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliSeconds))
}

export const logoutFromO365 = async (setState: React.Dispatch<React.SetStateAction<AppState>>, userName: string) => {
  Office.context.ui.displayDialogAsync(dialogLogoutUrl, { height: 40, width: 30 }, async (result) => {
    if (result.status === Office.AsyncResultStatus.Failed) {
      setState((prev) => ({ ...prev, errorMessage: `${result.error.code} ${result.error.message}` }))
    } else {
      logoutDialog = result.value
      logoutDialog.addEventHandler(Office.EventType.DialogMessageReceived, processLogoutMessage)
      logoutDialog.addEventHandler(Office.EventType.DialogEventReceived, processLogoutDialogEvent)
      await delay(5000) // Wait for dialog to initialize and register handler for messaging.
      logoutDialog.messageChild(JSON.stringify({ userName: userName }))
    }
  })

  const processLogoutMessage = () => {
    logoutDialog.close()
    setState((prev) => ({
      ...prev,
      authStatus: 'notLoggedIn',
      headerMessage: 'Welcome',
      userName: '',
    }))
  }

  const processLogoutDialogEvent = (arg) => {
    processDialogEvent(arg, setState)
  }
}

const processDialogEvent = (
  arg: { error: number; type: string },
  setState: React.Dispatch<React.SetStateAction<AppState>>
) => {
  switch (arg.error) {
    case 12002:
      setState((prev) => ({
        ...prev,
        errorMessage:
          'The dialog box has been directed to a page that it cannot find or load, or the URL syntax is invalid.',
      }))
      break
    case 12003:
      setState((prev) => ({
        ...prev,
        errorMessage: 'The dialog box has been directed to a URL with the HTTP protocol. HTTPS is required.',
      }))
      break
    case 12006:
      // 12006 means that the user closed the dialog instead of waiting for it to close.
      // It is not known if the user completed the login or logout, so assume the user is
      // logged out and revert to the app's starting state. It does no harm for a user to
      // press the login button again even if the user is logged in.
      setState((prev) => ({
        ...prev,
        authStatus: 'notLoggedIn',
        headerMessage: 'Welcome',
      }))
      break
    default:
      setState((prev) => ({
        ...prev,
        errorMessage: 'Unknown error in dialog box.',
      }))
      break
  }
}

export const generateWordDoc = (docText: string) => {
  const ps = []
  docText.split('\n').forEach((p) => {
    if (p) {
      if (p.startsWith('**')) {
        ps.push({
          type: 'heading-one',
          children: [
            {
              bold: true,
              text: p,
            },
          ],
        })
      } else if (p.startsWith('* **')) {
        ps.push({
          type: 'heading-two',
          children: [
            {
              bold: true,
              text: p,
            },
          ],
        })
      } else if (p.startsWith('    * ')) {
        ps.push({
          type: 'heading-three',
          children: [
            {
              bold: true,
              text: p,
            },
          ],
        })
      } else {
        ps.push({
          type: 'paragraph',
          children: [{ text: p }],
        })
      }
    }
  })
  return ps
}

export const generateAiPromptFromSelections = (clauseList: ClauseListInterface[]) => {
  // Create common prompt text
  // Added contract type TODO add other fields
  // contract type
  // let aiPrompt = `Generate ${values.contractType} Contract`
  // // owner and supplier
  // aiPrompt = `${aiPrompt} between (Customer) "${values.customer}" with customer address "${values.customerAddress}" and (Supplier) "${values.supplier}" `
  // // supplier address
  // aiPrompt = `${aiPrompt} with supplier address "${values.supplierAddress}" `
  // // effective at
  // aiPrompt = `${aiPrompt} contract is effective from "${values.effectiveat}" `
  // // End at
  // aiPrompt = `${aiPrompt} contract ends at "${values.endat}" `
  // // Renewal type
  // aiPrompt = `${aiPrompt} contract renewal by "${values.renewaltype}" `
  let aiPrompt =
    'Generate SOW Contract between (Customer) "Raindrop Systems" with customer address "2350 Mission College Boulevard Santa Clara California United States undefined" and (Supplier) "Canadian Association For Interventional"  with supplier address "1250 Rene Levesque West Montreal Quebec  CA H4b 3w8     "  contract is effective from "2024-01-15"  contract ends at "null"  contract renewal by "Evergreen" '

  clauseList.forEach((clause) => {
    if (clause.ai_prompt != null && clause.ai_prompt.length > 0 && clause.ai_prompt_enabled_default) {
      let isContractClausesAdded = false
      // fetch contract_clauses
      clause.contract_clauses.forEach((contractClause) => {
        if (contractClause.description != null && contractClause.description.length > 0 && contractClause.is_default) {
          aiPrompt = `${aiPrompt}\r\n - ${contractClause.description} `
          isContractClausesAdded = true
        }
        if (contractClause.requirement != null && contractClause.requirement.length > 0 && contractClause.is_default) {
          aiPrompt = `${aiPrompt}\r\n - ${contractClause.requirement} `
          isContractClausesAdded = true
        }
      })
      if (!isContractClausesAdded) {
        aiPrompt = `${aiPrompt}\r\n - ${clause.ai_prompt} `
      }
    }
  })
  // Adding include a signature block
  aiPrompt = `${aiPrompt}\r\n - include signature block at end.`
  return aiPrompt
}
